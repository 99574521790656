/* You can add global styles to this file, and also import other style files */
/*  */
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;0,400;0,600;0,700;1,200;1,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

/* @import "~@ng-select/ng-select/themes/material.theme.css"; */

body {
    font-family: 'Roboto', sans-serif !important;
}

body .fullscreen-modal {
    font-family: 'Roboto', sans-serif !important;
}

/* login css */
.login-logo {
    height: 80px;
    width: 80px;
    background-color: #1c3222;
    border-radius: 50px;
    padding: 10px;
}

.login-box {
    padding-top: 10px;
    width: 400px;
    margin: 0 auto;
    height: 70vh;
}

.form-container-inner {
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px solid #fff;
    margin: 0 auto;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0 10px 6px -6px #777;
    width: 400px;
}

header {
    z-index: 9999 !important;
}

.form-title {
    color: #1c3233;
    font-size: 36px;
    margin: 20px 0px;
    line-height: 40px;
}

.login-box .form-group {
    margin-top: 2rem;
}

.forgot-password-link {
    font-size: 12px;
    font-weight: 400;
    margin-right: 10px;
    float: right;
    color: #fff;
}

.forgot-password-link:hover {
    color: #00ffd5 !important;
}

/* login css end */
/* build no */
.version_no {
    font-size: 14px;
    color: #67757c;
    font-weight: 500;
    position: absolute;
    bottom: 18px;
    right: 15px;
}

/* user management css */

.navbar-brand {
    font-size: 20px;
    font-weight: 700;
    color: #1c3233 !important;
}

.navbar-light .navbar-nav .nav-link {
    color: #1c3233 !important;
    font-weight: 400;
    padding-right: 1rem;
    padding-left: 1rem;
    font-size: 16px;
}

.navbar-light .navbar-nav .nav-link:hover {
    text-decoration: underline;
    cursor: pointer;
}

.custome-drag {
    position: relative;
    background-color: #f6f6f6;
    border-radius: 2px;
    transition: border-color .3s, background-color .3s;
    margin: 20px 0px;
    width: 100%;
    height: 200px;

}

label {
    color: #111;
}

.custome-drag>input {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    opacity: 0;
    z-index: 99;
    cursor: pointer;
    width: 100%;
    height: 100%;
}

.custome-drag>label {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    margin-top: -9px;
    font-weight: 600;
    color: black;
}

.btn-rounded {
    border-radius: 4px !important;
}

.scroller-table .datatable-body {
    /* max-height:calc(70vh - 220px) !important; */
    width: 100%;
    height: calc(100vh - 375px) !important;
    overflow-y: auto !important;
    overflow-x: auto !important;
}

.scroller_Table .datatable-body {
    /* max-height:calc(70vh - 220px) !important; */
    width: 100%;
    height: calc(100vh - 284px) !important;
    overflow-y: auto !important;
    overflow-x: auto !important;
}

.scr-table .datatable-body {
    /* max-height:calc(70vh - 220px) !important; */
    width: 100%;
    height: calc(100vh - 355px) !important;
    overflow: auto !important;
    /* overflow-y:auto !important; */
    /* overflow-x:auto !important; */
}

.scr_Table .datatable-body {
    /* max-height:calc(70vh - 220px) !important; */
    width: 100%;
    height: calc(100vh - 410px) !important;
    overflow: auto !important;
    /* overflow-y:auto !important;  */
    /* overflow-x:auto !important;  */
}

.ngx-datatable.material {
    border-bottom: 0 !important;
}

.ngx-datatable.material.single-selection .datatable-body-row.active,
.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-selection .datatable-body-row.active,
.ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
    background-color: #c5f7ef !important;
    color: transparent !important;
}

.datatable-header-cell {
    text-align: left;
    color: #fff !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    background-color: #1c3233 !important;
    padding: 0.9rem 1rem !important;
}

.datatable-body-cell {
    border-bottom: #f3f1f1 1px solid !important;
    font-size: 12px !important;
    padding: 0.6rem 1rem !important;
}

/* .datatable-body {
    width: 100%;
    height: 637px !important;
  } */

angular2csv>button {
    cursor: pointer;
    background: #00ffd5 !important;
    color: #00ffd5 !important;
    padding: .375rem .25rem !important;
    border: none !important;
    width: 50px;
    border-radius: 4px !important;
    float: left;
    margin-right: 10px;
}

angular2csv>button {
    color: transparent;
    /* hides 'download' label */
    line-height: 0;
    height: 37px;
    /* Collapse the original line */
}

angular2csv>button::after {
    content: "CSV";
    /* new button label */
    color: #263238 !important;
    /* color so we can see it */
    display: block;
    line-height: initial;
    /* New content takes up original line height */
}

/* user management css end */

/* tenants css */
.nav-pills {
    border-bottom: #EEE 1px solid !important;
    padding: 15px;
}

.nav-pills .nav-link {
    padding: 5px 14px !important;
    font-size: 14px !important;
    margin-left: 1rem;
    transition: .4s ease-in-out;
    border-radius: 0 !important;
}

.nav-pills .nav-link:hover {
    background-color: #00ffd5 !important;
    color: #263238 !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #263238 !important;
    background-color: #00ffd5 !important;
}

/* user management css end */
/* primary button css */
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
    color: #263238 !important;
    background-color: #00ffd5 !important;
    border-color: #00ffd5 !important;
}

.btn-primary:focus,
.btn-primary.focus {
    color: #fff;
    background-color: #00ffd5 !important;
    border-color: #00ffd5 !important;
    box-shadow: none !important
}

/* primary button css end */

.ico_docs,
.ico_docs_file {
    width: 100px;
    display: inline-block;
    height: 100px;
    text-align: center;
}

.ico_docs img {
    width: 70px;
    margin-top: 15px;
    height: 70px;
    object-fit: contain;
}

.ico_docs_file img {
    width: 70px;
    height: 70px !important;
    margin-top: 15px;
}

.loader {
    background-image: url('./assets/images/ZZ5H.gif');
    display: block;
    width: 50px;
    height: 50px;
    background-size: cover;
}

.tasks .loader {
    background-image: url('./assets/images/ZZ5H.gif');
    display: block;
    width: 30px;
    height: 30px;
    background-size: cover;
}

/*--- Verticle Side-bar-About Menu at bottom ---*/

.scroll-sidebar {
    height: calc(100% - 0px) !important;
}

.sidebar-nav {
    position: relative;
    height: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
}

.sidebar-nav>ul {
    position: relative;
    height: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
}

/* .sidebar-nav > ul > .sidebar-item:last-child {position:absolute; bottom:0; margin-bottom:0 ; width:100%;} */
/* .sidebar-nav > ul > .custom-sidebar-item {position:absolute; bottom:0; margin-bottom:0 ; width:100%;} */

/* upload files css */
.main.modal-body {
    max-height: 70vh !important;
    overflow: auto !important;
}

.main.modal-header {
    margin-bottom: 20px;
}

.main.modal-footer {
    margin-top: 20px;
}

.bg-1c3233 {
    background-color: #1c3233;
}

.badge {
    cursor: pointer;
}

/* upload files css end */
.page-item.active .page-link {
    color: #263238 !important;
    border-color: #a6b7bf !important;
    white-space: nowrap;
}

.page-link {
    color: #5f696d !important;
}

.pagination {
    justify-content: end;
}

/* For Data Tables */
.data-table .card-body {
    padding: 0 !important
}

/* margins customization */
.master-page-margin {
    margin-top: 40px !important;
}

.data-table.alert-messages {
    position: fixed;
    top: 50px;
    left: 50%;
    z-index: 99999999 !important;
    transform: translate(-50%, -50%);
}

.alert-messages {
    position: fixed !important;
    top: 50px !important;
    left: 50% !important;
    z-index: 99999999 !important;
    transform: translate(-50%, -50%) !important;
}

.ngx-datatable.material .datatable-body .progress-linear .container {
    /* display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 5px;
    transform: translate(0, 0) scale(1, 1); */
    background-color: #67757c !important;
}

.ngx-datatable.material .datatable-body .progress-linear .container .bar {
    /* transition: all 0.2s linear;
    -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
    animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
    transition: transform 0.2s linear; */
    background-color: #f6f6f6 !important;
    /* position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 5px; */
}

/* upload component css */
.files-list {
    margin-top: 1.5rem;
}

.files-list .single-file img.delete {
    margin-left: 0.5rem;
    cursor: pointer;
    align-self: flex-end;
}

.files-list .single-file .name {
    font-size: 14px;
    font-weight: 500;
    color: #353f4a;
    margin: 0;
}

.files-list .single-file .size {
    font-size: 12px;
    font-weight: 500;
    color: #a4a4a4;
    margin: 0;
    margin-bottom: 0.25rem;
}

.files-list .single-file .info {
    width: 100%
}

.files-list .single-file {
    display: flex;
    padding: 0.5rem;
    justify-content: space-between;
    align-items: center;
    border: dashed 1px #979797;
    /* margin-bottom: 1rem; */
    display: flex;
    flex-grow: 1;
}

.upload-div-text {
    font-weight: 600;
    color: rgb(29, 100, 16);
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .08) !important;
}

/* upload component css end */
/* progress bar */
.progress {
    display: flex;
    height: 11px !important;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: #ebf3f5;
    border-radius: 2px;
}

.progress-bar {
    background-color: #398bf7 !important;
}

.popup {
    border-radius: 20px;
    width: 40%;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: 0;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06) !important;
}

.MatchedText_list {
    /* height: calc(100vh - 335px) !important; */
    /* overflow: auto; */
}

.scr-table {
    width: 100%;
    height: calc(100vh - 180px) !important;
    overflow: auto !important;
}

.scr_Table {
    width: 100%;
    height: calc(100vh - 250px) !important;
    overflow: auto !important;
}

.mm-table {
    height: calc(100vh - 335px) !important;
}

.mediamonks_Table {
    height: calc(100vh - 250px) !important;
}

.no-pagination {
    /* width: 100%; */
    height: calc(100vh - 280px) !important;
    /* overflow: auto !important; */
}

/* Sortable table demo */

th[sortData] {
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
}

th[sortData].desc:before,
th[sortData].asc:before {
    content: '';
    display: block;
    background: url('./assets/images/arrowDowns.png') no-repeat;
    background-size: cover;
    width: 12px;
    height: 12px;
    margin-left: -12px;
    float: right;
    position: absolute;
    top: 16px;
}

th[sortData].desc:before {
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
}

.logs-table th[sortData].desc:before,
th[sortData].asc:before {
    content: '';
    display: block;
    background: url('./assets/images/arrowDowns.png') no-repeat;
    background-size: cover;
    width: 12px;
    height: 12px;
    margin-left: -12px;
    /* float: right; */
    position: absolute;
    top: 18px;

}

/* modal animation */
.modal-content {
    animation-name: example;
    animation-duration: 0.3s;
}

@keyframes example {
    0% {
        transform: scale(0.5)
    }

    75% {
        transform: scale(1.1)
    }

    100% {
        transform: scale(1)
    }
}

.modal-xl {
    max-width: 1000px !important;
    width: 80% !important;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    height: 90% !important;
}

.datatables .table td {
    padding: 0 !important;
}

.google-icon {
    background-image: url('./assets/images/google-icon.jpeg');
    background-repeat: no-repeat;
    /* background-position: center; */
    width: 25px;
    height: 25px;
    background-size: cover;
    position: absolute;
    left: 60px;
}

.modal {
    z-index: 99999 !important;
}


/* accordian */
.list-details-accordion .card-header:first-child {
    border: none;
}

.list-details-accordion .card-header {
    padding: 0 !important;
    margin-bottom: 0;
    background-color: #1c3233 !important;
    border-bottom: 0px solid transparent;
    /* border-radius: 0px 5px 5px 0 !important; */
}

.list-details-accordion .btn-link {
    color: #fff !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    /* margin-left: 20px !important; */
}

.list-details-accordion .panel-body.card-block.card-body {
    background-color: #353f4a0a;
    border: 1px solid #cccc !important;
    border-radius: 0 0 6px 6px !important;
}

/* Roles Multiselect Dropdown */
.multiselect-dropdown .dropdown-btn .selected-item {
    border: 1px solid #3798ecfa !important;
    background: #3798ecfa !important;
    padding: 3px 6px !important;
    border-radius: 6px !important;
}

.nav-tabs .nav-link.active {
    color: #fff !important;
    background-color: #1c3233 !important;
    border-color: #1c3233 !important;
}

.hamburger-menu {
    position: relative !important;
    top: -20px !important;
    margin: 10px !important;
}

.hamburger-btn {
    display: flex;
    margin-left: -24px;
}

.multiselect-dropdown .dropdown-btn .selected-item {
    border: 1px solid #00ffd5 !important;
    background: #00ffd5 !important;
}

.multiselect-dropdown .dropdown-btn .selected-item {
    color: #1c3333 !important;
}

.multiselect-dropdown .dropdown-btn .selected-item a {
    color: #1c3333 !important;
}

.multiselect-item-checkbox input[type=checkbox]+div:before {
    border: 2px solid #04ffd5 !important;
}

.multiselect-item-checkbox input[type=checkbox]:checked+div:before {
    background: #04ffd5 !important;
}

.multiselect-item-checkbox input[type=checkbox]+div:after {
    border-color: #1c3333 !important;
}

.list-details-accordion .card-header {
    border-radius: 4px 4px 0 0 !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
    right: -6px !important;
    top: 2px !important;
}

/* datepicker css */
.theme-green .bs-datepicker-head {
    background-color: #1c3233 !important;
}

.theme-green .bs-datepicker-body table td span.selected {
    background-color: #1c3233 !important;
}

.theme-green .bs-datepicker-body table td.week span {
    color: #1c3233 !important;
}

.watermark {
    background-image: url('./assets/images/watermark.png');
    background-repeat: no-repeat;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.05;
    filter: grayscale(10);
}


/* file manager css starts */
/* file manager css starts */
/* file manager css starts */

.dx-filemanager .dx-filemanager-container .dx-drawer-panel-content.dx-drawer-panel-content-initial {
    background-color: #fff !important;
}

.dx-filemanager {
    margin: -20px -20px -20px -20px !important;
    height: calc(100vh - 60px) !important;
}

.dx-filemanager .dx-filemanager-items-panel {
    background-color: #fff !important;
}

;

.dx-toolbar .dx-toolbar-item:last-child {
    padding: 0;
    background: aqua !important;
    z-index: 111111111;
}

/* file manager css ends */
/* file manager css ends */
/* file manager css ends */

.search-bar .autocomplete-container {
    box-shadow: none !important;
}

.search-bar .autocomplete-container .input-container input {
    font-size: 14px;
    box-sizing: border-box;
    border: 1px solid #cccc !important;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, 0.318);
    color: #000000de;
    width: 100%;
    padding: 0 15px;
    line-height: 40px;
    height: 40px;
    /* position: absolute;
    right: -18rem !important; */

}

@media (max-width: 768px) {
    .search-bar .autocomplete-container .input-container input {
        width: 60%;
    }

    .search-bar .input-container {
        position: relative !important;
    }

    .search-bar .autocomplete-container .input-container .x i {
        position: absolute !important;
        right: 6.7rem !important;
        top: -10px !important;
    }

    .search-bar {
        position: absolute;
        top: 85px;
        z-index: 1;
        width: 300px;
        left: 250px;
    }
}

.toggle-view-off {
    width: 0 !important;
    transition: width 0.2s;
}

.toggle-view-on {
    width: 260px !important;
    transition: width 0.2s;
}


@media (max-width: 799px) {
    .toggle-icon {
        display: none;
    }

    .toggle-view-off {
        width: 260px !important;
        transition: width 0.2s;
    }
}

@media (min-width: 800px) {
    .hamburger-menu {
        display: none !important;
    }
}



.run-job-dropdown .dropdown-menu {
    left: -70px !important;
}

.popover {
    left: -110px !important;
}

.bs-popover-bottom>.arrow::after,
.bs-popover-auto[x-placement^=bottom]>.arrow::after {
    right: -110px !important;
}

.bs-popover-bottom>.arrow::before,
.bs-popover-auto[x-placement^=bottom]>.arrow::before {
    right: -110px !important;
}

.job-card-height {
    height: 100px;
}

@media (max-width:1230px) {
    .job-card-height {
        height: 130px;
    }
}

@media (max-width:850px) {
    .job-card-height {
        height: 150px;
    }
}

@media (max-width:767px) {
    .job-card-height {
        height: auto;
    }
}

.filter-dropdown .dropdown-menu {
    width: 500px !important;
}

.filter-dropdown .dropdown-menu.show {
    left: -450px !important;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
}

.loader-content {
    position: absolute !important;
    top: 50% !important;
    left: 50%;
    transform: translate(0, -50%) !important;
    z-index: 1;
}

.show.tooltip {
    z-index: 9999999 !important;
}

.seperator {
    border-right: 1px solid #fff;
    padding: 6px 0;
}

.dropdown-toggle::after {
    display: none !important;
}

.dropdown-custom .dropdown-menu.show {
    left: -130px !important;
}

@media (min-width: 1291px) {
    .dropdown-custom .dropdown-menu.show {
        left: -130px !important;
    }
}

.autocomplete-container .suggestions-container ul {
    max-height: 190px !important;
}



/* ***************************** Data Dictionary sidebar*******************************  */
/* ***************************** Data Dictionary sidebar*******************************  */
/* ***************************** Data Dictionary sidebar*******************************  */

/* // sidebar */

.data-manager-sidebar .sidebar {
    position: fixed;
    top: 60px;
    width: 250px;
    height: 100%;
    background-color: #fff;
    border-right: 1px solid #ccc;
    z-index: 1;
    transition: all 0.3s ease;
}

.data-manager-sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.data-manager-sidebar li a {
    display: block;
    padding: 10px;
    color: #555;
    text-decoration: none;
    transition: all 0.3s ease;
}

.data-manager-sidebar li a:hover {
    background-color: #fff;
    color: #1e88e5;
}

.data-manager-sidebar .sidebar.show {
    left: 0;
    top: 110px;
}


/* colors */
.bg-7d2ae8 {
    background-color: #1e88e5;
}

.bg-EDEDED {
    background-color: #EDEDED;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #455a64;
}

p,
li,
dd,
dt {
    color: #67757c;
}

.content ul li a {
    color: #1F88E6;
}

.cursor {
    cursor: pointer !important;
}

.selected-node{
    font-weight: bold;
    margin-right:10px;
}
label,textarea{
    font-weight: 300 !important;
}

.searchModel .title{
    transition: 0.s ease-in;
    font-weight: 500;
    font-size: 18px;
    color: #4b86e5;
}
.searchModel .title:hover{
    text-decoration: underline;
}
.font-16{ font-size:  16px;}
.font-14{ font-size:  14px;}
.font-12{ font-size:  12px;}
.fw-500 { font-weight: 500;}
.lh-15 { line-height: 18px;}